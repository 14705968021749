document.addEventListener("DOMContentLoaded", () => {
  const navList = document.querySelectorAll(".nav-list__item");
  const navAbout = navList[0];
  const navDev = navList[1];
  const navWorkshops = navList[2];
  const navConsult = navList[3];
  const navContact = navList[4];
  const workshopButtons = document.querySelectorAll(".workshops__button");
  const workArch = workshopButtons[0];
  const workDotnet = workshopButtons[1];
  const workDocker = workshopButtons[2];
  const workTesting = workshopButtons[3];
  const burger = document.querySelector(".burger");
  const navMobileContainer = document.querySelector(".nav-mobile");
  const navMobile = document.querySelectorAll(".nav-mobile-list__item");
  const workshopDescriptions = document.querySelectorAll(
    ".workshops__description"
  );
  const submitButton = document.getElementById("submit");

  const openMobileNav = () => {
    navMobileContainer.classList.add("active");
    navMobile.forEach((element) => {
      element.classList.remove("closed");
      element.classList.add("active");
    });
  };

  const closeMobileNav = () => {
    navMobileContainer.classList.remove("active");
    navMobile.forEach((element) => {
      element.classList.remove("active");
      element.classList.add("closed");
    });
  };

  burger.addEventListener("click", () => {
    if (navMobileContainer.classList.contains("active"))
      return closeMobileNav();
    if (!navMobileContainer.classList.contains("active"))
      return openMobileNav();
  });

  navMobile[0].addEventListener("click", () => {
    document.querySelector(".about").scrollIntoView({
      behavior: "smooth",
    });
    closeMobileNav();
  });
  navMobile[1].addEventListener("click", () => {
    document.querySelector(".development").scrollIntoView({
      behavior: "smooth",
    });
    closeMobileNav();
  });
  navMobile[2].addEventListener("click", () => {
    document.querySelector(".workshops").scrollIntoView({
      behavior: "smooth",
    });
    closeMobileNav();
  });
  navMobile[3].addEventListener("click", () => {
    document.querySelector(".consulting").scrollIntoView({
      behavior: "smooth",
    });
    closeMobileNav();
  });
  navMobile[4].addEventListener("click", () => {
    document.querySelector(".contact").scrollIntoView({
      behavior: "smooth",
    });
    closeMobileNav();
  });

  navAbout.addEventListener("click", () => {
    document.querySelector(".about").scrollIntoView({
      behavior: "smooth",
    });
  });
  navDev.addEventListener("click", () => {
    document.querySelector(".development").scrollIntoView({
      behavior: "smooth",
    });
  });
  navWorkshops.addEventListener("click", () => {
    document.querySelector(".workshops").scrollIntoView({
      behavior: "smooth",
    });
  });
  navConsult.addEventListener("click", () => {
    document.querySelector(".consulting").scrollIntoView({
      behavior: "smooth",
    });
  });
  navContact.addEventListener("click", () => {
    document.querySelector(".contact").scrollIntoView({
      behavior: "smooth",
    });
  });

  const clearWorkshopSelection = () => {
    workshopButtons.forEach((button) => {
      button.classList.remove("active");
    });
    workshopDescriptions.forEach((description) => {
      description.classList.remove("active");
    });
  };

//   workArch.addEventListener("click", (e) => {
//     if (!e.target.classList.contains("active")) {
//       clearWorkshopSelection();
//       e.target.classList.add("active");
//       document.querySelector(".architecture").classList.add("active");
//     }
//   });
//   workDotnet.addEventListener("click", (e) => {
//     if (!e.target.classList.contains("active")) {
//       clearWorkshopSelection();
//       e.target.classList.add("active");
//       document.querySelector(".dotNet").classList.add("active");
//     }
//   });
//   workDocker.addEventListener("click", (e) => {
//     if (!e.target.classList.contains("active")) {
//       clearWorkshopSelection();
//       e.target.classList.add("active");
//       document.querySelector(".docker").classList.add("active");
//     }
//   });
//   workTesting.addEventListener("click", (e) => {
//     if (!e.target.classList.contains("active")) {
//       clearWorkshopSelection();
//       e.target.classList.add("active");
//       document.querySelector(".testing").classList.add("active");
//     }
//   });

  const clearForm = () => {
    document.getElementById("name").value = "";
    document.getElementById("body").value = "";
    document.getElementById("title").value = "";
    document.getElementById("sender").value = "";
  }

  submitButton.addEventListener("click", (e) => {
    e.preventDefault();

    submitButton.disabled = true;

    const FORM_API =
      "https://linecode-func.azurewebsites.net/api/SendMail?code=7omk1QUSOxZteuBS8S9Fth452L7bEOQggaM3RMTvBcx3IiISaS02og==";

    const data = {
      name: document.getElementById("name").value,
      body: document.getElementById("body").value,
      title: document.getElementById("title").value,
      sender: document.getElementById("sender").value,
    };
    fetch(FORM_API, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'no-cors',
      body: JSON.stringify(data),
    })
      .then(() => {
         clearForm();
        alert("Message sent.");
        submitButton.disabled = false
      })
      .catch((err) => {
        alert("An error has occurred.");
        submitButton.disabled = false
      });
  });
});
