import * as OfflinePluginRuntime from "offline-plugin/runtime";

import "./fonts/libre-baskerville-v5-latin-regular.woff";
import "./fonts/libre-baskerville-v5-latin-regular.woff2";
import "./fonts/BODR.woff";
import "./fonts/BODR.woff2";
import "./fonts/opensans-bold-webfont.woff";
import "./fonts/opensans-bold-webfont.woff2";
import "./fonts/opensans-regular-webfont.woff";
import "./fonts/opensans-regular-webfont.woff2";
import "./fonts/opensans-semibold-webfont.woff";
import "./fonts/opensans-semibold-webfont.woff2";
import "./fonts/PlayfairDisplay-Black.woff";
import "./fonts/PlayfairDisplay-Black.woff2";

import "./index.html";
import "./index.scss";
import "./scripts/script";

OfflinePluginRuntime.install();
